<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>Your Business and Regulations</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body" v-if="!regulations.readonlyMode">
            <div class="edit-form">
                <ValidationObserver v-slot="{ passes }">
                    <form @submit.prevent="passes(regulationsForm)" id="business-structure" ref="form">
                        <ValidationProvider name="Industry Classification" rules="required" v-slot="{ errors}">
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':regulations.industryClassification && !errors[0]}">
                                    <span class="float-label" :class="{'multiselect--disabled':regulations.readonlyMode}">
                                    <label for="Country">Industry Classification*</label>
                                        <multiselect
                                            :disabled="regulations.readonlyMode"
                                            placeholder=""
                                            v-model="regulations.industryClassification" 
                                            :show-labels="false" 
                                            :options="industryClassification_option" 
                                            :searchable="false">
                                        </multiselect>  
                                        <i class="fa fa-times" v-if="errors.length && errors[0] && !regulations.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="regulations.industryClassification && !errors[0] && !regulations.readonlyMode"></i>
                                    </span>
                                </div>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="ISIC Code" rules="required" v-slot="{ errors}">
                            <div class="country-dropdown">
                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':regulations.ISIC_code && !errors[0]}">
                                    <span class="float-label" :class="{'multiselect--disabled':regulations.readonlyMode}">
                                    <label for="Country">Confirm your Businesses ISIC Code*</label>
                                        <multiselect
                                            :disabled="regulations.readonlyMode"
                                            placeholder=""
                                            v-model="regulations.ISIC_code" 
                                            :show-labels="false" 
                                            :options="ISICCode_option" 
                                            :searchable="false">
                                        </multiselect>  
                                        <i class="fa fa-times" v-if="errors.length && errors[0] && !regulations.readonlyMode"></i>
                                        <i class="fa fa-check" v-if="regulations.ISIC_code && !errors[0] && !regulations.readonlyMode"></i>
                                    </span>
                                </div>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Registration Number" rules="required" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !regulations.registration_Number,'success':regulations.registration_Number}">
                                <span class="float-label">
                                    <input type="text" v-model="regulations.registration_Number" class="form-control" id="registration_Number" placeholder="Registration Number*" :disabled="regulations.readonlyMode">
                                    <label for="registration_Number" class="control-label">Registration Number*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !regulations.registration_Number && !regulations.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="regulations.registration_Number && !regulations.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Tax-ID" rules="required" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !regulations.Tax_ID,'success':regulations.Tax_ID}">
                                <span class="float-label">
                                    <input type="text" v-model="regulations.Tax_ID" class="form-control" id="Tax_ID" placeholder="Business Tax ID*" :disabled="regulations.readonlyMode">
                                    <label for="Tax_ID" class="control-label">Business Tax ID*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !regulations.Tax_ID && !regulations.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="regulations.Tax_ID && !regulations.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':regulations.readonlyMode}" v-if="!regulations.readonlyMode">
                            <p class="mb-0 mr-3 custom-switch-text">Is your business regulated?</p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="isRegulated" v-model="regulations.isRegulated" :disabled="regulations.readonlyMode">
                                <label class="custom-switch-label" for="isRegulated">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <ValidationProvider name="regulated_number" :rules="regulations.isRegulated ? 'required' : ''" v-slot="{ errors}" v-if="regulations.isRegulated && !regulations.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !regulations.regulatedNumber,'success':regulations.regulatedNumber}">
                                <span class="float-label">
                                    <input type="text" v-model="regulations.regulatedNumber" class="form-control" id="regulated_number" placeholder="Licence/Regulated Number*" :disabled="regulations.readonlyMode">
                                    <label for="regulated_number" class="control-label">Licence/Regulated Number*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !regulations.regulatedNumber && !regulations.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="regulations.regulatedNumber && !regulations.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':regulations.readonlyMode}" v-if="!regulations.readonlyMode">
                            <p class="mb-0 mr-3 custom-switch-text">Is your business registered for VAT/Sales Tax? </p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="isSalesTax" v-model="regulations.isSalesTax" :disabled="regulations.readonlyMode">
                                <label class="custom-switch-label" for="isSalesTax">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <ValidationProvider name="tax_number" :rules="regulations.isSalesTax ? 'required' : ''" v-slot="{ errors}" v-if="regulations.isSalesTax && !regulations.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !regulations.taxNumber,'success':regulations.taxNumber}">
                                <span class="float-label">
                                    <input type="text" v-model="regulations.taxNumber" class="form-control" id="tax_number" placeholder="VAT/Sales Tax Number*" :disabled="regulations.readonlyMode">
                                    <label for="tax_number" class="control-label">VAT/Sales Tax Number*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !regulations.taxNumber && !regulations.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="regulations.taxNumber && !regulations.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                         <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':regulations.readonlyMode}" v-if="!regulations.readonlyMode">
                            <p class="mb-0 mr-3 custom-switch-text">Do you have an AML Policy?</p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="isAMLPolicy" v-model="regulations.isAMLPolicy" :disabled="regulations.readonlyMode">
                                <label class="custom-switch-label" for="isAMLPolicy">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <ValidationProvider name="policy_number" :rules="regulations.isAMLPolicy ? 'required' : ''" v-slot="{ errors}" v-if="regulations.isAMLPolicy && !regulations.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !regulations.AMLPolicyNumber,'success':regulations.AMLPolicyNumber}">
                                <span class="float-label">
                                    <input type="text" v-model="regulations.AMLPolicyNumber" class="form-control" id="policy_number" placeholder="Registration Number*" :disabled="regulations.readonlyMode">
                                    <label for="policy_number" class="control-label">Registration Number*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !regulations.AMLPolicyNumber && !regulations.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="regulations.AMLPolicyNumber && !regulations.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':regulations.readonlyMode}" v-if="!regulations.readonlyMode">
                            <p class="mb-0 mr-3 custom-switch-text">Is your business registered for Data Protection?</p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="isDataProtection" v-model="regulations.isDataProtection" :disabled="regulations.readonlyMode">
                                <label class="custom-switch-label" for="isDataProtection">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <ValidationProvider name="dataProtection_number" :rules="regulations.isDataProtection ? 'required' : ''" v-slot="{ errors}" v-if="regulations.isDataProtection && !regulations.readonlyMode">
                            <div class="form-group" :class="{'errors': errors.length && !regulations.dataProtectionNumber,'success':regulations.dataProtectionNumber}">
                                <span class="float-label">
                                    <input type="text" v-model="regulations.dataProtectionNumber" class="form-control" id="dataProtection_number" placeholder="Registration Number*" :disabled="regulations.readonlyMode">
                                    <label for="dataProtection_number" class="control-label">Registration Number*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !regulations.dataProtectionNumber && !regulations.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="regulations.dataProtectionNumber && !regulations.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':regulations.readonlyMode}" v-if="!regulations.readonlyMode">
                            <p class="mb-0 mr-3 custom-switch-text">Is your business PCI Certified? </p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="isPCICertified" v-model="regulations.isPCICertified" :disabled="regulations.readonlyMode">
                                <label class="custom-switch-label" for="isPCICertified">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <ValidationProvider name="regulations_certification" :rules="regulations.isPCICertified ? 'required' : ''" v-slot="{ errors }" v-if="regulations.isPCICertified && !regulations.readonlyMode">
                            <div class="form-group datepicker" :class="{'errors': errors[0],'success':regulations.certificationDate && !errors[0]}">
                                <span class="float-label">
                                    <b-input-group :class="{'datepicker-disable':regulations.readonlyMode}">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Date of Last Certification</span>
                                        </div>
                                        <datepicker class="form-control" :bootstrap-styling="false" placeholder="" v-model="regulations.certificationDate" :disabled="regulations.readonlyMode" :typeable="true"></datepicker>
                                        <template #append>
                                            <span class="input-group-text"><i class="icon-calendar"></i></span>
                                        </template>
                                    </b-input-group>
                                    <i class="fa fa-times" v-if="errors.length && !regulations.certificationDate && !regulations.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="regulations.certificationDate && !regulations.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div class="d-flex align-items-center justify-content-between mb-3" :class="{'switch-disable':regulations.readonlyMode}" v-if="!regulations.readonlyMode">
                            <p class="mb-0 mr-3 custom-switch-text">Is your business ISO27001 Certified? </p>
                            <div class="custom-switch success-handle">
                                <input type="checkbox" class="custom-switch-input" id="isISOCertified" v-model="regulations.isISOCertified">
                                <label class="custom-switch-label" for="isISOCertified">
                                    <span class="custom-switch-text1">Yes</span>
                                    <span class="custom-switch-text2">No</span>
                                </label>
                            </div>
                        </div>
                        <ValidationProvider name="regulations_ISO" :rules="regulations.isISOCertified ? 'required' : ''" v-slot="{ errors }" v-if="regulations.isISOCertified && !regulations.readonlyMode">
                            <div class="form-group datepicker" :class="{'errors': errors[0],'success':regulations.ISOCertifiedDate && !errors[0]}">
                                <span class="float-label">
                                    <b-input-group :class="{'datepicker-disable':regulations.readonlyMode}">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Date of Last Certification</span>
                                        </div>
                                        <datepicker class="form-control" :bootstrap-styling="false" placeholder="" v-model="regulations.ISOCertifiedDate" :disabled="regulations.readonlyMode" :typeable="true"></datepicker>
                                        <template #append>
                                            <span class="input-group-text"><i class="icon-calendar"></i></span>
                                        </template>
                                    </b-input-group>
                                    <i class="fa fa-times" v-if="errors.length && !regulations.ISOCertifiedDate && !regulations.readonlyMode"></i>
                                    <i class="fa fa-check" v-if="regulations.ISOCertifiedDate && !regulations.readonlyMode"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <div v-if="!regulations.readonlyMode" class="justify-content-center d-flex">
                            <b-button size="lg" @click="regulationsForm()" class="btn-mw">Cancel</b-button>
                            <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
const Datepicker = require('vuejs-datepicker').default
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";

const Multiselect = require('vue-multiselect').default

export default {
    name: 'BusinessRegulations-Profile',
    components:{
        Datepicker,
        ValidationObserver,
        ValidationProvider,
        Multiselect
    },
    data(){
        return{
            regulations:{
                readonlyMode:true,
                industryClassification:['Hospitality'],
                ISIC_code: ['NACE – EU'],
                registration_Number:'036895C4',
                Tax_ID:'0785554228682',
                isRegulated:false,
                regulatedNumber:'089654228682',
                isSalesTax:false,
                taxNumber:'',
                isAMLPolicy:false,
                AMLPolicyNumber:'',
                isDataProtection:false,
                dataProtectionNumber:'',
                isPCICertified:false,
                certificationDate:null,
                isISOCertified:false,
                ISOCertifiedDate:''

            },
            industryClassification_option: ['Hospitality', 'Taxes & Legal', 'Industrial & Warehouse', 'Real Estate'],
            ISICCode_option: ['NACE – EU','NAICS – US','UKSIC - UK'],
        }
    },methods:{
        editDetails(){
            this.regulations.readonlyMode = false
        },

        //submit Personal Details information
        regulationsForm(){
            this.regulations.readonlyMode  = true
        },
    }
}
</script>
